import { obtenerToken, MSGERROR } from '../librerias/funciones'
import store from '../store';

const endpoint1 = "https://assist.servicenext.eu/API_TALLER/"
//const endpoint1 = "http://86.109.106.108/API_NEXTASSIST_TALLER/"
//const endpoint2 = "https://nextassist.serca.es/API/"


let controller = new AbortController();


/*

const buscarMatriculaCocheV2 = (matricula) => {
    return axios({
        method: 'GET',
        url: endpoint1 + "api/TecnicoNextAssist/" + matricula,
    }).then(function (res) {
        if (res.data !== undefined) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            return res.data
        }
    });
}



const GetSmartPackEnAPI = (ID_VEHICULO) => {

    return axios({
        method: 'post',
        url: endpoint2 + "api/Heynes/GetSmartPack",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            ID_VEHICULO: ID_VEHICULO,
            GRUPO: ""
        })
    }).then(function (res) {
        if (res.data !== undefined) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            return res.data
        }
    });
}





const GetDetalleSmartPackEnAPI = (ID_SISTEMA, TIPO_TBS) => {
    return axios({
        method: 'post',
        url: endpoint2 + "/api/Heynes/GetDetalleSmartPack",
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            ID_SISTEMA: ID_SISTEMA,
            TIPO_TBS: TIPO_TBS
        })
    }).then(function (res) {
        if (res.data !== undefined) {
            if (!res.data.OK) {
                MSGERROR(res.data.MENSAJE_ERROR)
            }
            return res.data
        }
    });
}
*/



const LoginEnAPI = (USUARIO, PWD) => {
    //  return fetch(endpoint1 + 'Accesos/Login', {
    //https://assist.servicenext.eu/test/Accesos/Login
    controller = new AbortController();
    return fetch(endpoint1 + 'Accesos/Login', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                USUARIO: USUARIO,
                PWD: PWD
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                /*
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                */
                return res;
            }
        })
}


const LogoutEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Accesos/Logout', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res;
            }
        })
}


const obtener_registros_paginadosEnAPI = (REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, INCLUIR_BAJAS, INCLUIR_CERRADAS, ID_TALLER, ID_USUARIO_TALLER, FECHA_PARTIDA, ACCION_DESDE, ACCION_HASTA) => {
    controller = new AbortController();
    // 'Tareas/obtener_registros_paginados'
    //return fetch(endpoint1 +  'Tareas/obtener_registros_paginados', {
    //https://assist.servicenext.eu/test/tareas/actualizar_feedback

    return fetch(endpoint1 + 'Tareas/obtener_registros_paginados', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                REGSPAGE: 0,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                //INCLUIR_BAJAS: INCLUIR_BAJAS,  
                INCLUIR_CERRADAS: INCLUIR_CERRADAS,
                //ID_TALLER:ID_TALLER,
                //ID_USUARIO_TALLER:ID_USUARIO_TALLER,
                FECHA_PARTIDA: FECHA_PARTIDA,
                ACCION_DESDE: ACCION_DESDE,
                ACCION_HASTA: ACCION_HASTA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res;
            }
        })
}



const obtener_fabricantesEnAPI = (TIPO_VEHICULO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'VehiculoTecdoc/obtener_fabricantes', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                TIPO_VEHICULO: TIPO_VEHICULO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const obtener_modelosEnAPI = (FABRICNATE) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'VehiculoTecdoc/obtener_modelos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                FABRICANTE: FABRICNATE,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const obtener_motorizacionesEnAPI = (ID_MODELO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'VehiculoTecdoc/obtener_motorizaciones', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_MODELO: ID_MODELO,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_tipo_tareaEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Maestras/obtener_tipo_tarea', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_grupo_montajeEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Maestras/obtener_grupo_montaje', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_estados_tareaEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Maestras/obtener_estados_tarea', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_paises_EnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Maestras/obtener_paises', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const buscar_matriculaEnAPI = (MATRICULA, PAIS) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Matriculas/buscar_matricula', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                MATRICULA: MATRICULA,
                PAIS: PAIS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const buscar_bastidorEnAPI = (BASTIDOR, PAIS) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Matriculas/buscar_bastidor', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                BASTIDOR: BASTIDOR,
                PAIS: PAIS,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const obtener_fichero_tecnico_EnAPI = (ID) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Archivos/Tecnico/obtener', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}
const obtener_fichero_taller_EnAPI = (ID) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Archivos/obtener', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_registrosEnAPI = (ID_TAREA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Archivos/Tecnico/obtener_registros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TAREA: ID_TAREA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_registros_taller_EnAPI = (ID_TAREA) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Archivos/obtener_registros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TAREA: ID_TAREA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const insertar_ficheroEnAPI = (ID_TAREA, NOMBRE, EXTENSION, DATOS) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Archivos/insertar', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_TAREA: ID_TAREA,
                NOMBRE: NOMBRE,
                EXTENSION: EXTENSION,
                DATOS: DATOS,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const nueva_tareaEnAPI = (PAYLOAD) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Tareas/nuevo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_MAE_PAIS_TAREA: PAYLOAD.ID_MAE_PAIS_TAREA,
                ID_VEHICULO_TECDOC: PAYLOAD.ID_VEHICULO_TECDOC,
                MATRICULA_MARCA_ID: PAYLOAD.MATRICULA_MARCA_ID,
                MATRICULA_MODELO_ID: PAYLOAD.MATRICULA_MODELO_ID,
                MATRICULA_MOTOR_ID: PAYLOAD.MATRICULA_MOTOR_ID,
                BASTIDOR: PAYLOAD.BASTIDOR,
                MATRICULA: PAYLOAD.MATRICULA,
                MATRICULA_MARCA: PAYLOAD.MATRICULA_MARCA,
                MATRICULA_MODELO: PAYLOAD.MATRICULA_MODELO,
                MATRICULA_MOTOR: PAYLOAD.MATRICULA_MOTOR,
                MATRICULA_CODIGO_MOTOR: PAYLOAD.MATRICULA_CODIGO_MOTOR,
                MATRICULA_PAIS: PAYLOAD.MATRICULA_PAIS,
                ID_TALLER: PAYLOAD.ID_TALLER,
                ID_MAE_PLATAFORMA: PAYLOAD.ID_MAE_PLATAFORMA,
                ID_MAE_GRUPO_MONTAJE: PAYLOAD.ID_MAE_GRUPO_MONTAJE,
                ID_MAE_PRIORIDAD_TAREA: PAYLOAD.ID_MAE_PRIORIDAD_TAREA,
                ID_MAE_TIPO_TAREA: PAYLOAD.ID_MAE_TIPO_TAREA,
                TEXTO: PAYLOAD.TEXTO,
                CODIGO_AVERIA: PAYLOAD.CODIGO_AVERIA,
                TEXTO_RESPUESTA: PAYLOAD.TEXTO_RESPUESTA,
                ID_MAE_GRADO_SATISFACCION: PAYLOAD.ID_MAE_GRADO_SATISFACCION,
                RESOLUCION_DE_TAREAS: PAYLOAD.RESOLUCION_DE_TAREAS,
                ID_MAE_ESTADO_TAREA: PAYLOAD.ID_MAE_ESTADO_TAREA,
                ID_USUARIO_ASIGNADO: PAYLOAD.ID_USUARIO_ASIGNADO,
                ID_USUARIO_EN_CURSO: PAYLOAD.ID_USUARIO_EN_CURSO,
                ID_USUARIO_RESPUESTA: PAYLOAD.ID_USUARIO_RESPUESTA,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const GetSmartPackEnAPI = (ID_VEHICULO_TECDOC) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.HEYNES_SMART_TOKEN;

    return fetch(endpoint1 + 'Heynes/obtener_boletines_reparacion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
            GRUPO: "",
            HEYNES_SMART_TOKEN: HEYNES_TOKEN,

            Token: obtenerToken()
        }

        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const GetDetalleSmartPackEnAPI = (ID_SISTEMA, TIPO_TBS) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.HEYNES_SMART_TOKEN;

    return fetch(endpoint1 + 'Heynes/obtener_detalle_boletin', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_SISTEMA: ID_SISTEMA,
                TIPO_TBS: TIPO_TBS,

                HEYNES_SMART_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const obtener_vehiculoEnAPI = (ID_VEHICULO) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'VehiculoTecdoc/obtener_vehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const obtener_imagen_vehiculoEnAPI = (ID_VEHICULO) => {
    controller = new AbortController();
    let HEYNES_TOKEN = store.getState().usuario.HEYNES_DIST_TOKEN;

    return fetch(endpoint1 + 'VehiculoTecdoc/obtener_imagen_vehiculo', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO_TECDOC: ID_VEHICULO,
                HEYNES_DIST_TOKEN: HEYNES_TOKEN,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const recuperar_contrasenyaEnAPI = (CORREO) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Accesos/recuperar_contrasenya', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                CORREO_ELECTRONICO: CORREO,
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_usuario_EnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Usuario/obtener', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const actualizar_usuario_EnAPI = (PAYLOAD, PWD) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Usuario/actualizar', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                USUARIO: PAYLOAD.USUARIO,
                PWD: PWD,
                ID_IDIOMA: PAYLOAD.ID_IDIOMA,
                CORREO_RECUPERACION_PWD: PAYLOAD.CORREO_RECUPERACION_PWD,
                OBSERVACIONES: PAYLOAD.OBSERVACIONES,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_registros_idiomas_EnAPI = (ORDERCOLUM, ORDERDIR) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Idiomas/obtener_registros', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_configuracion_EnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Configuracion/obtener', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {

                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_tarea_EnAPI = (ID) => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Tareas/obtener', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_anyos_EnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + 'Tareas/anyos', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const obtener_grado_satisfaccionEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Maestras/obtener_grado_satisfaccion', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}



const hay_feedback_pendientesEnAPI = () => {
    controller = new AbortController();
    return fetch(endpoint1 + "Tareas/hay_feedback_pendientes", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const actualizar_feedbackEnAPI = (ID_TAREA, ID_GRADO, TEXTO_RESOLUCION) => {
    controller = new AbortController();
    return fetch(endpoint1 + "tareas/actualizar_feedback", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID_TAREA,
                ID_MAE_GRADO_SATISFACCION: ID_GRADO,
                RESOLUCION_DE_TAREAS: TEXTO_RESOLUCION,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}

const documento_consutlaEnAPI = (ID) => {
    controller = new AbortController();
    return fetch(endpoint1 + "tareas/documento_consulta", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID: ID,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const obtener_haynes_urlEnAPI = () => {
    controller = new AbortController();

    return fetch(endpoint1 + "Heynes/obtener_haynes_url", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const obtener_conectorEnAPI = (ID_VEHICULO, CODIGO_MOTOR) => {
    controller = new AbortController();
    return fetch("https://assist.servicenext.eu/TEST/" + "VehiculoTecdoc/obtener_conector", {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                ID_VEHICULO: ID_VEHICULO,
                CODIGO_MOTOR: CODIGO_MOTOR,
                Token: obtenerToken()
            }
        ),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {
            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}




const solicitud_informacion_servicioEnAPI  = (SERVICIO) => {
    controller = new AbortController();
    return fetch(endpoint1 + 'Tareas/solicitud_informacion_servicio', {
        method: 'POST',
        signal: controller.signal,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            SERVICIO :SERVICIO,
            Token: obtenerToken()
        }),
        dataType: "json",
    }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(function (res) {

            if (res !== undefined) {
                if (!res.OK) {
                    MSGERROR(res.MENSAJE_ERROR)
                }
                return res
            }
        })
}


const abortFetch = () => {
    controller.abort()
}
const END_POINT_ACTUAL = () => {
    return endpoint1;
}

export {
    abortFetch,
    LoginEnAPI,
    LogoutEnAPI,
    obtener_registros_paginadosEnAPI,
    obtener_fabricantesEnAPI,
    obtener_modelosEnAPI,
    obtener_motorizacionesEnAPI,
    obtener_tipo_tareaEnAPI,
    obtener_grupo_montajeEnAPI,
    buscar_matriculaEnAPI,
    buscar_bastidorEnAPI,
    nueva_tareaEnAPI,
    obtener_estados_tareaEnAPI,
    obtener_registrosEnAPI,
    insertar_ficheroEnAPI,
    GetSmartPackEnAPI,
    GetDetalleSmartPackEnAPI,
    obtener_vehiculoEnAPI,
    obtener_fichero_tecnico_EnAPI,
    obtener_fichero_taller_EnAPI,
    obtener_paises_EnAPI,
    obtener_imagen_vehiculoEnAPI,
    obtener_registros_taller_EnAPI,
    recuperar_contrasenyaEnAPI,
    obtener_usuario_EnAPI,
    actualizar_usuario_EnAPI,
    obtener_registros_idiomas_EnAPI,
    obtener_configuracion_EnAPI,
    obtener_anyos_EnAPI,
    obtener_tarea_EnAPI,
    END_POINT_ACTUAL,
    obtener_grado_satisfaccionEnAPI,
    hay_feedback_pendientesEnAPI,
    actualizar_feedbackEnAPI,
    documento_consutlaEnAPI,
    obtener_conectorEnAPI,
    obtener_haynes_urlEnAPI,
    solicitud_informacion_servicioEnAPI
};